/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';
// import 'bootstrap';
// ================================
// START YOUR APP HERE
import L from 'leaflet';

// ================================
const map = L.map('map');

L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
}).addTo(map);

const popupCV = L.marker([47.667702, -2.985268])
  .addTo(map)
  .bindPopup('<h3>Biocoop Horizon Bio Centre-Ville</h3><p><a href="https://www.google.com/maps/place/Biocoop+Horizon+Bio/@47.667703,-2.9874667,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xeb568113669133d0!8m2!3d47.667703!4d-2.985278?hl=fr" target="_blank"><i class="fas fa-map-marker"></i> 37 rue Jean-Marie Barré, 56400 Auray</a><br/><br/><a href="tel:+33297507295"><i class="fas fa-phone"></i> 02 97 50 72 95</a></p>')
  .openPopup();

const popupPO = L.marker([47.66445, -3.00436])
  .addTo(map)
  .bindPopup('<h3>Biocoop Horizon Bio Porte Océane</h3><p><a href="https://www.google.com/maps/place/Grand+March%C3%A9+Bio/@47.6644327,-3.0041931,19.33z/data=!4m5!3m4!1s0x48101460b0aa0ae7:0x1b788cee898699c6!8m2!3d47.6644659!4d-3.0043376?hl=fr" target="_blank"><i class="fas fa-map-marker"></i> 40 avenue de l\'Océan, 56400 Auray</a><br/><br/><a href="tel:+33973889946"><i class="fas fa-phone"></i> 09 73 88 99 46</a></p>')

map.on('load', function(e) {
  popupPO.openPopup();
});
map.setView([47.6662,-2.9966], 13);